import request from '@/utils/request'


// 民政- 获取基本信息
export function getGovInfo(id) {
    return request({
        url: '/gov/' + id,
        method: 'get',
    })
}
// 民政- 获取社区信息
export function getGovToCommFindAll(data) {
    return request({
        url: '/gov/govToCommFindAll',
        method: 'post',
        data: data
    })
}
// 民政- 获取铺设情况
export function getFindByUserReviewToTal(data) {
    return request({
        url: '/gov/findByUserReviewToTal',
        method: 'post',
        data: data
    })
}

// 民政 - 获取查询老人年龄分布
export function getGovFindByAgeList(data) {
    return request({
        url: `/gov/findByAgeList`,
        method: 'post',
        data: data
    })
}

// 民政 - 获取设备状态
export function getGovFindByDeviceStatusToTal(data) {
    return request({
        url: `/gov/findByDeviceStatusToTal`,
        method: 'post',
        data: data
    })
}

// 民政 - 预警新增记录
export function getGovFindByWarnDataToTal(data) {
    return request({
        url: `/gov/findByWarnDataToTal`,
        method: 'post',
        data: data
    })
}
// 民政 - 设备预警信息
export function getGovFindByDeviceWarnToTal(data) {
    return request({
        url: `/gov/findByDeviceWarnToTal`,
        method: 'post',
        data: data
    })
}
// 民政 - 电话预警信息
export function getGovFindByCallWarnToTal(data) {
    return request({
        url: `/gov/findByCallWarnToTal`,
        method: 'post',
        data: data
    })
}
// 民政 - 根据propertyId 查询数据信息
export function getGovFindByGovToPropertyInfo(propertyId) {
    return request({
        url: `/gov/findByGovToPropertyInfo/` + propertyId,
        method: 'get',
    })
}
// 民政 - 账号退出更新时间
export function getGovLoginOut(propertyId) {
    return request({
        url: `/gov/loginOut/` + propertyId,
        method: 'get',
    })
}