<template>
  <div class="pageBox">
    <div class="logoBox" @click="openHKlink">
      <div class="logo">
        <img src="../../assets/img/logo_w.svg" alt="" />
      </div>
    </div>
    <el-container class="mainBox">
      <div class="title">你好，欢迎登录菏康养老民政监管中心</div>
      <div class="from">
        <el-form ref="signInform" :model="signInform" :rules="signInRules" class="form_connter">
          <div class="from_item">
            <div class="from_item_vlaue">账号</div>
            <div class="from_item_key">
              <el-input class="inputBox" v-model="signInform.mobile" placeholder="登录账号或负责人手机号"></el-input>
            </div>
          </div>
          <div class="from_item">
            <div class="from_item_vlaue">密码</div>
            <div class="from_item_key">
              <el-input v-model="signInform.password" type="password" placeholder="请输入登录密码"></el-input>
            </div>
          </div>
          <div class="from_item">
            <el-button type="primary" class="btn" @click="openLogin">登录</el-button>
          </div>
          <div class="from_item">
            <!-- <div class="switch">还没账号，<span class="register" @click="openRegister">去注册</span></div> -->
          </div>
        </el-form>
      </div>
      <div class="support" @click="openHKlink">©️菏康提供技术支持</div>
    </el-container>
  </div>
</template>
<script>
import { login, tokenParse } from '@/api/login.js'
import session from '@/utils/session'
export default {
  data() {
    return {
      signInform: {
        // host:'',
        mobile: '',
        password: ''
      },
      signInRules: {
        mobile: [{ required: true, trigger: 'blur', message: '账号不能为空' }],
        password: [{ required: true, trigger: 'blur', message: '密码不能为空' }]
      }
    }
  },
  methods: {
    async openLogin() {
      let result = await login(this.signInform)
      if (result.success) {
        const { data } = result
        session.setSession(data)
        this.parseToken(data)
        this.$message.success(result.message)
      } else if (result.code == 20001) {
        this.$message.error(result.message)
      }
    },
    //解析token
    async parseToken(token) {
      let result = await tokenParse(token)
      session.setData('userInfo', result.data)
      this.$router.push({ path: '/index' })
      console.log('userInfo', result.data)
    },
    openRegister() {
      this.$router.push({ path: '/register' })
    },
    openHKlink() {
      const w = window.open('about:blank')
      w.location.href = 'https://www.zghkyy.com'
    }
  }
}
</script>
<style lang="less" scoped>
.pageBox {
  height: 100vh;
  widows: 100vw;
  background: linear-gradient(#023a3a, #011b1b);
}
.logoBox {
  cursor: pointer;
  position: fixed;
  top: 0;
  width: 100vw;
  padding: 24px;
  .logo {
    width: 100px;
  }
}
.mainBox {
  display: block;
  padding-top: 120px;
}
.register {
  cursor: pointer;
}
.title {
  font-size: 40px;
  color: #fff;
  text-align: center;
}
.from {
  margin: auto;
  width: 480px;
  margin-top: 120px;
  background-image: url('../../assets/img/login_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .form_connter {
    padding: 40px;
  }
  .from_item {
    padding: 12px 0;
    /deep/ .el-input__inner {
      color: #fff;
      background: rgba(9, 190, 190, 0.1);
      border: rgba(9, 190, 190, 0.2);
    }
    .from_item_vlaue {
      color: #fff;
      padding-bottom: 16px;
    }
    .from_item_key {
      display: flex;
      align-items: center;
    }
    .btn {
      margin-top: 16px;
      width: 100%;
    }
    .switch {
      display: flex;
      justify-content: center;
      color: #fff;
      span {
        color: #09bebe;
        font-weight: 500;
      }
    }
  }
}
.support {
  color: rgba(255, 255, 255, 0.5);
  cursor: pointer;
  position: fixed;
  bottom: 24px;
  text-align: center;
  width: 100vw;
}
.support:hover {
  color: #fff;
}
</style>
